import React from 'react';
import { TrackedLink as Link } from 'components/common/TrackedLink';
import styled from 'styled-components';
import { CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';

export function CourseCardImage({ path, course }) {
  const { featureImageUrl, progress } = course;
  const completed = !!progress?.salesforceCourseResultId;

  return (
    <StyledCardImage className="card-image">
      <section>
        <Link to={path}>
          <img src={featureImageUrl} alt="" />
        </Link>
        {completed && (
          <div className="course-completed-overlay">
            <section>
              <i className="fa fa-check" />
            </section>
            <section>Completed</section>
          </div>
        )}
      </section>
    </StyledCardImage>
  );
}

const StyledCardImage = styled.section`
  img {
    display: block;
    width: 100%;
  }
  section {
    position: relative;
    .course-completed-overlay {
      align-items: center;
      background: ${CEB_COLOR_RGBA('DUSTY_TEAL', 0.85)};
      bottom: 0;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      left: 0;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
      section {
        flex: 0 1 auto;
        font-family: 'Zilla Slab', serif;
        font-size: 17px;
        font-weight: bold;
        i {
          font-size: 32px;
        }
      }
    }
  }
`;
