import { gql } from '@apollo/client';

const GET_SEARCH_TOTALS = gql`
  query GetSearchTotals(
    $query: String!
    $citedCaseJudicataId: String
    $published: CasePublicationStatus = BOTH
    $textFilters: [String]
    $optionFilters: [String]
    $caseDateGte: Year
    $caseDateLte: Year
    $date: String!
  ) {
    publications: search(
      type: PUBLICATION
      query: $query
      filters: { textFilters: $textFilters }
    ) {
      totalCount
    }
    cases: search(
      type: CASE
      query: $query
      filters: {
        citedCaseJudicataId: $citedCaseJudicataId
        casePublicationStatus: $published
        textFilters: $textFilters
        caseDate_gte: $caseDateGte
        caseDate_lte: $caseDateLte
      }
    ) {
      totalCount
    }
    statutes: search(
      type: STATUTE
      query: $query
      filters: { textFilters: $textFilters }
    ) {
      totalCount
    }
    practitioner: search(
      type: PRACTITIONER
      query: $query
      filters: { textFilters: $textFilters }
    ) {
      totalCount
    }
    news: currentAwarenessSearch(
      query: $query
      filters: {
        originalPublishDate: $date
        PostResourceTypes: ["Law Alert", "In Practice", "Legal News"]
      }
    ) {
      totalCount
    }
    courtRules: search(
      type: GENERIC
      query: $query
      filters: { textFilters: $textFilters, optionFilters: $optionFilters }
    ) {
      totalCount
    }
    course: courseSearch(query: $query) {
      totalCount
    }
  }
`;

export default GET_SEARCH_TOTALS;
