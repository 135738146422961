import React from 'react';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import styled from 'styled-components';

export function CourseProgressBar({ courseProgress }) {
  const percentage = !!courseProgress?.salesforceCourseResultId
    ? 100
    : courseProgress?.completedPercentage;
  return <ProgressBar percent={percentage} />;
}

export function ProgressBar({ percent, ...props }) {
  return (
    <StyledCourseProgressBar completed={percent === 100} {...props}>
      <progress id="courseProgressBar" value={percent || 0} max="100" />
      <label htmlFor="courseProgressBar">{percent || 0}%</label>
    </StyledCourseProgressBar>
  );
}

const StyledCourseProgressBar = styled.div`
  display: flex;
  label {
    flex: 0 1 auto;
    min-width: 2em;
    padding-left: 1em;
    text-align: right;
    &:hover {
      cursor: default;
    }
  }
  progress {
    background: ${CEB_COLOR('MERCURY')};
    border-radius: 7px;
    border: 0;
    flex: 1 1 auto;
    height: 14px;
    margin-top: 3px;
    &::-webkit-progress-bar {
      background: ${CEB_COLOR('MERCURY')};
      border-radius: 7px;
    }
    &::-webkit-progress-value {
      background: ${({ completed }) =>
        CEB_COLOR(completed ? 'DUSTY_TEAL' : 'COOL_BLUE')};
      border-radius: 7px;
    }
  }
`;
