import React from 'react';
import pluralize from 'util/pluralize';
import styled from 'styled-components';

export const CLELibraryRequirements = ({ requirements, color }) =>
  requirements.length > 0 &&
  requirements.map(({ credits, parentName, name }, i) => (
    <StyledRequirement key={`${parentName}${credits}${i}`}>
      <StyledRequirementBubble color={color}>
        {credits} {pluralize(credits, 'Hr')}
      </StyledRequirementBubble>
      {parentName === 'Special Requirements' ? name : parentName}
    </StyledRequirement>
  ));

export const StyledRequirementBubble = styled.span.attrs(
  ({ color = '#4099cf' }) => ({
    color,
  }),
)`
  display: inline-block;
  font-family: Lato;
  font-size: 12px;
  font-weight: 900;
  padding: 2px 7px;
  border-radius: 3px;
  background-color: ${({ color }) => color};
  color: white;
  margin-right: 8px;
  margin-bottom: 8px;
`;

export const StyledRequirement = styled.div`
  display: inline-block;
  font-family: 'BasisGrotesque', sans-serif;
  font-size: 14px;
  margin-right: 8px;
  white-space: nowrap;
`;
