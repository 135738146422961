import React from 'react';
import styled from 'styled-components';
import { BackButton } from 'components/common/BackButton';

export default function CLELibraryPageHeader({
  withBackButton = false,
  children,
}) {
  return (
    <StyledPageHeader className={children && 'has-children'}>
      {withBackButton && <BackButton />}
      <h2>CLE Library</h2>

      {children}
    </StyledPageHeader>
  );
}

export const StyledPageHeader = styled.header`
  display: flex;
  align-items: center;
  margin: 0 60px 40px;

  &.has-children {
    display: block;
    @media screen and (min-width: 1166px) {
      display: flex;
    }
  }

  button {
    display: flex;
    align-items: center;
    i {
      font-size: 20px;
      color: #343434;
      margin-right: 6px;
    }
    background-color: rgba(52, 52, 52, 0.11);
    padding: 8px 14px;
    font-family: 'BasisGrotesque', sans-serif;
    font-size: 16px;
    border-radius: 4px;
  }
  h2 {
    font-family: 'LyonDisplay', serif;
    font-size: 54px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    margin-left: 24px;
    white-space: nowrap;
  }
`;
