import React from 'react';
import { LayoutContext } from './LayoutContext';

export function useIsRawLayout() {
  const layout = React.useContext(LayoutContext);
  const raw = React.useMemo(() => {
    return layout?.raw;
  }, [layout]);

  return raw;
}
