import React from 'react';
import styled from 'styled-components';
import TitledPage from 'components/common/TitledPage';
import { useCoursesCollection } from 'components/learning/cle-collections/useCoursesCollection';
import CLELibraryPageHeader from '../CLELibraryPageHeader';
import { CLELibraryPackageSidebar } from './CLELibraryPackageSidebar';
import { CLELibraryPackageDetails } from './CLELibraryPackageDetails';
import { useCollectionMetaData } from 'hooks/useMetaData';

export default function CLELibraryPackage() {
  const {
    data: { coursesCollection },
  } = useCoursesCollection();

  const collectionMetaData = useCollectionMetaData(
    coursesCollection,
    'CEB CLE Library',
  );
  return (
    <TitledPage
      title={collectionMetaData.title}
      meta={collectionMetaData.meta}
      link={collectionMetaData.link}
      defaultTitle={collectionMetaData.defaultTitle}
      titleTemplate={collectionMetaData.titleTemplate}
    >
      <CLELibraryPageHeader withBackButton />
      <StyledCourseCollection>
        <CLELibraryPackageDetails collection={coursesCollection} />
        <CLELibraryPackageSidebar collection={coursesCollection} />
      </StyledCourseCollection>
    </TitledPage>
  );
}

const StyledCourseCollection = styled.section`
  display: grid;
  grid-template-areas: 'main sidebar';
  grid-template-columns: 1fr min-content;
  margin: 0 auto;
  max-width: 1200px;
  > section:first-child {
    grid-area: main;
  }
  > div:last-child {
    grid-area: sidebar;
  }
`;
