import { gql } from '@apollo/client';

export const CourseData = gql`
  fragment CourseData on CLECourse {
    contentfulId
    slug
    title
    dateRecorded
    panoptoId
    featureImageUrl
    totalCredits
    courseMaterialsFileName
    courseMaterialsUrl
    longDescription
    practiceAreas {
      contentfulId
      name
      slug
    }
    specialRequirementsCredits {
      contentfulId
      parentContentfulId
      parentName
      credits
      name
    }
    specializationCredits {
      contentfulId
      parentContentfulId
      parentName
      credits
      name
    }
    speakers {
      contentfulId
      slug
      webDisplayName
    }
    collections {
      title
      slug
      compliancePackageProductCode
    }
  }
`;

const GET_CLE_COURSE = gql`
  query getCourse($slug: String!) {
    course(slug: $slug) {
      ...CourseData
    }
  }

  ${CourseData}
`;

export default GET_CLE_COURSE;
