import React from 'react';
import styled from 'styled-components';
import { TrackedLink } from 'components/common/TrackedLink';
import { StyledAccessSection } from './StyledAccessSection';
import { CEB_COLOR, CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';

export function CoursePassAccess({ hoursRemaining = 0, total }) {
  return (
    <StyledCoursePassAccessSection className="coursepass-access-section">
      <section>
        <div>
          <img
            src="/images/subscriptions/logos/coursepass.svg"
            alt="CoursePass Logo"
          />
        </div>
        <>
          {hoursRemaining > 0 ? (
            <div className="message">
              <div>
                Your CoursePass gives you access to our entire CLE library for a
                set number of credit hours.
              </div>
            </div>
          ) : (
            <>
              <div className="message">
                <div>
                  You have used all your CoursePass hours and no longer have
                  access to CLE.
                </div>
              </div>
              <div>
                <div>
                  <TrackedLink to="/accounts/purchases">
                    View Plans &amp; Purchases
                  </TrackedLink>
                </div>
              </div>
            </>
          )}
        </>

        <div className="hours">
          <div>Hours Remaining:</div>
          <div className="remaining">
            <span className={!hoursRemaining ? 'empty' : 'valid'}>
              {hoursRemaining}
            </span>
            <span>/{total}</span>
          </div>
        </div>
      </section>
      <section>
        {hoursRemaining > 0 ? (
          <div className="unlimited">
            <div>
              To gain unlimited access to our entire CLE library and meet all
              your CLE and professional development needs at a lower price,{' '}
              <b>
                <TrackedLink to="/account/purchases/upgrade-mcle">
                  upgrade to a CLEPassport.
                </TrackedLink>
              </b>
            </div>
          </div>
        ) : (
          <div className="expand">
            <div>
              <i className="fas fa-exclamation-circle" />
              <b>Don’t stop learning!</b>{' '}
              <TrackedLink to="/account/purchases/upgrade-mcle">
                Expand your practice with a CLE Passport
              </TrackedLink>{' '}
              or{' '}
              <TrackedLink to="/account/purchases/upgrade-mcle">
                buy more CoursePass hours
              </TrackedLink>
            </div>
          </div>
        )}
      </section>
    </StyledCoursePassAccessSection>
  );
}
const StyledCoursePassAccessSection = styled(StyledAccessSection)`
  > section {
    display: flex;
    + section {
      margin-top: 24px;
    }
    > div {
      &.expand {
        flex: 1 1 auto;
        > div {
          background: ${CEB_COLOR('RAW_SIENNA')};
          border-radius: 2px;
          color: white;
          font-family: 'Zilla Slab', serif;
          font-size: 16px;
          padding: 8px 12px;
          > i {
            font-size: 22px;
            margin-right: 10px;
            vertical-align: -3px;
          }
          a,
          a:visited {
            color: white;
            text-decoration: underline;
          }
        }
      }
      &.unlimited {
        border: 1px solid ${CEB_COLOR('QUILL_GRAY')};
        padding: 8px 17px 10px 31px;
        font-family: 'Zilla Slab', serif;
        font-size: 15px;
        font-weight: normal;
        font-style: normal;
        line-height: 1.47;
        flex: 1 1 auto;
      }

      &.hours {
        white-space: nowrap;
        div {
          &.remaining {
            color: ${CEB_COLOR_RGBA('BLACK', 0.4)};
            font-family: 'Zilla Slab', serif;
            font-size: 26px;
            font-weight: bold;
            margin-left: 13px;
            .valid {
              color: ${CEB_COLOR('BLACK')};
            }
          }
        }
      }
    }
  }
`;
