import React from 'react';

const RemoveIcon = ({ fill = '#29364F' }) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.786 9.214a.647.647 0 0 1-.643.643H4.429a.647.647 0 0 1-.643-.643V7.93c0-.352.291-.643.643-.643h7.714c.351 0 .643.291.643.643v1.285zM16 8.571A7.716 7.716 0 0 0 8.286.857 7.716 7.716 0 0 0 .57 8.571a7.716 7.716 0 0 0 7.715 7.715A7.716 7.716 0 0 0 16 8.57z"
      fill={fill}
      fillRule="nonzero"
    />
  </svg>
);

export default RemoveIcon;
