import { ContentfulContent } from 'components/common/ContentfulContent';
import { PracticeAreaTags } from 'components/daily-news/PracticeAreaTags';
import { StyledCLELibrarySearchResult } from 'components/learning/cle-courses';
import { StyledContentfulContent } from 'components/learning/cle-courses/Course';
import React from 'react';
import styled from 'styled-components';
import { CLECourseRequirements } from '../course/CLECourseRequirements';
import { useCoursePracticeAreaPathBuilder } from '../landing/useCoursePracticeAreaPathBuilder';

export function CLELibraryPackageDetails({ collection }) {
  const practiceAreaPathBuilder = useCoursePracticeAreaPathBuilder('/');
  return (
    <StyledPackageDetails>
      <div className="package-details">
        <article>
          <section>
            <h3>{collection.title}</h3>
            <CLECourseRequirements
              totalCredits={collection.totalCredits}
              specializationCredits={collection.specializationCredits}
              specialRequirementsCredits={collection.specialRequirementsCredits}
            />
          </section>
          <section role="main">
            <h4>Course Description</h4>
            <StyledContentfulContent>
              <ContentfulContent document={collection.description} />
            </StyledContentfulContent>
          </section>
          <section>
            <h4>Practice Areas</h4>
            <PracticeAreaTags
              practiceAreas={collection.practiceAreas}
              pathBuilder={practiceAreaPathBuilder}
            />
          </section>
        </article>
      </div>
      <div className="included-courses">
        <h4 className="subheader">Included Courses</h4>
        <StyledCourseList>
          <ul>
            {collection.courses.map((course, index) => (
              <StyledCLELibrarySearchResult
                key={course.contentfulId}
                data={course}
                rootSlugs={[]}
                path={`/course/${course.slug}`}
                collection={collection}
              />
            ))}
          </ul>
        </StyledCourseList>
      </div>
    </StyledPackageDetails>
  );
}

const StyledPackageDetails = styled.section`
  margin: 0 35px;
  .package-details {
    h3 {
      font-family: LyonDisplay;
      font-size: 46px;
      margin-bottom: 36px;
    }
  }
  .package-details h4,
  .subheader {
    font-family: BasisGrotesque;
    font-size: 16px;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 8px;
  }
  .included-courses {
    margin-top: 36px;
  }
`;

const StyledCourseList = styled.div`
  ul {
    list-style-type: none;
  }
`;
