import React from 'react';
import { useWindowFocus } from './useWindowFocus';

export function useRefetchOnRefocus(refetch: () => void) {
  const focused = useWindowFocus();
  const lastRenderFocusState = React.useRef(focused);

  React.useEffect(() => {
    if (focused && lastRenderFocusState.current !== focused) {
      refetch && refetch();
    }
    lastRenderFocusState.current = focused;
  }, [refetch, focused]);
}
