import React from 'react';
import styled from 'styled-components';
import { StyledCourseButton } from './StyledCourseButton';
import { useCLEAccess } from '../landing/access';

export function AssetAccessButton({ course, collection, hideAlert }) {
  const access = useCLEAccess(
    course?.collections.map(
      ({ compliancePackageProductCode }) => compliancePackageProductCode,
    ) || [collection.compliancePackageProductCode],
  );

  return (
    <>
      {!hideAlert && NO_ACCESS_MESSAGE[access.type] && (
        <AccessAlert>{NO_ACCESS_MESSAGE[access.type]}</AccessAlert>
      )}

      <StyledCourseButton to={'/account/purchases/upgrade-mcle'}>
        <i className="fas fa-list" />
        See Access Options
      </StyledCourseButton>
    </>
  );
}

export function useHasAccess(course, productCodes) {
  const access = useCLEAccess(
    productCodes ||
      course?.collections?.map(
        ({ compliancePackageProductCode }) => compliancePackageProductCode,
      ),
  );

  return React.useMemo(() => {
    switch (access.type) {
      case 'package':
        return access.packageAccess;
      case 'coursepass':
        return access.hoursRemaining > 0;
      default:
        return access.type !== false;
    }
  }, [access]);
}

export function useHasCollectionAccess(collection) {
  const access = useCLEAccess([collection.compliancePackageProductCode]);

  return React.useMemo(() => {
    switch (access.type) {
      case 'package':
        return access.packageAccess;
      case 'coursepass':
        return access.hoursRemaining > 0;
      default:
        return access.type !== false;
    }
  }, [access]);
}

const NO_ACCESS_MESSAGE = {
  coursepass:
    'You have used your CoursePass hours. Purchase additional time or upgrade to CLEPassport.',
  package: 'This course is not included in your Compliance package',
};

function AccessAlert({ children }) {
  return (
    <StyledAccessAlert>
      <div>
        <i className="fas fa-exclamation-circle" />
      </div>
      <div>{children}</div>
    </StyledAccessAlert>
  );
}

const StyledAccessAlert = styled.section`
  background: var(--ATTITUDE_COLORS_CAUTION);
  color: white;
  display: flex;
  font-family: 'Zilla Slab', serif;
  font-size: 16px;
  border-radius: 3px;
  margin-bottom: 12px;

  div {
    flex: 1 1 auto;
    align-self: center;
    padding: 10px 12px 10px 12px;
    + div {
      padding-left: 6px;
    }
    &:first-child {
      flex: 0 1 auto;
      padding-right: 6px;
      i {
        font-size: 24px;
      }
    }
  }
`;
