import { gql } from '@apollo/client';

const PLAN_OFFERING_FRAGMENT = gql`
  fragment planOffering on ContentfulPlanOffering {
    slug
    name
    providesCle
    providesLegalResearch
    startingAtPrice
    priceIntervalText
    shortDescription
    sidebarPlanOfferingBulletpoints {
      json
    }
  }
`;

export const GET_MCLE_OFFERING = gql`
  query getMcleOffering {
    coursepass: contentfulPlanOfferingCollection(
      where: { slug: "coursepass" }
      limit: 1
    ) {
      items {
        ...planOffering
      }
    }
    compliancePackage: contentfulPlanOfferingCollection(
      where: { slug: "compliance-package" }
      limit: 1
    ) {
      items {
        ...planOffering
      }
    }
    passport: contentfulPlanOfferingCollection(
      where: { slug: "passport" }
      limit: 1
    ) {
      items {
        ...planOffering
      }
    }
  }
  ${PLAN_OFFERING_FRAGMENT}
`;

export const GET_RESEARCH_OFFERING = gql`
  query getResearchOffering {
    onlawpro: contentfulPlanOfferingCollection(
      where: { slug: "onlaw-pro" }
      limit: 1
    ) {
      items {
        ...planOffering
      }
    }
    practitioner: contentfulPlanOfferingCollection(
      where: { slug: "practitioner" }
      limit: 1
    ) {
      items {
        ...planOffering
      }
    }
  }
  ${PLAN_OFFERING_FRAGMENT}
`;
