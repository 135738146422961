import React from 'react';
import useQueryString from 'hooks/useQueryString';
import useSuspendableQuery from 'hooks/useSuspendableQuery';
import { useLocation } from 'react-router';
import qs from 'util/qs';
import GET_COURSE_SEARCH from './getCourseSearch';
import dayjs from 'dayjs';

export function useCourseSearch(userPracticeAreas, otherVariables = {}) {
  const {
    practiceAreas,
    specialRequirements,
    specializationCredits,
    dateRecorded_gte,
    dateRecorded_lte,
    dateRecorded,
    yourPracticeAreas,
    ...queryString
  } = useQueryString();
  const page = React.useMemo(
    () => (queryString.page ? parseInt(queryString.page, 10) : 1),
    [queryString.page],
  );
  const variables = React.useMemo(
    () => ({
      query: queryString.query,
      page,
      practiceAreas:
        userPracticeAreas && yourPracticeAreas === 'true'
          ? [
              ...(practiceAreas ? practiceAreas.split(',') : []),
              ...userPracticeAreas.map(area => area.sys.id),
            ]
          : practiceAreas,
      specialRequirements,
      specializationCredits,
      dateRecorded_gte:
        dateRecorded === 'custom' &&
        dateRecorded_gte &&
        dateRecorded_gte.length === 4
          ? dayjs(`${dateRecorded_gte}-01-01`).format()
          : undefined,
      dateRecorded_lte:
        dateRecorded === 'custom' &&
        dateRecorded_lte &&
        dateRecorded_lte.length === 4
          ? dayjs(`${dateRecorded_lte}-12-31`).format()
          : undefined,
      ...otherVariables,
    }),
    [
      queryString,
      page,
      practiceAreas,
      specialRequirements,
      specializationCredits,
      dateRecorded,
      dateRecorded_gte,
      dateRecorded_lte,
      yourPracticeAreas,
      userPracticeAreas,
      otherVariables,
    ],
  );

  const { data } = useSuspendableQuery(GET_COURSE_SEARCH, { variables });

  const { pathname } = useLocation();

  const href = React.useCallback(
    page =>
      `${pathname}?${qs.stringify({
        practiceAreas,
        specialRequirements,
        specializationCredits,
        dateRecorded_gte,
        dateRecorded_lte,
        dateRecorded,
        yourPracticeAreas,
        ...queryString,
        page,
      })}`,
    [
      pathname,
      practiceAreas,
      specialRequirements,
      specializationCredits,
      dateRecorded_gte,
      dateRecorded_lte,
      dateRecorded,
      yourPracticeAreas,
      queryString,
    ],
  );

  return [data, page, href];
}
