import { gql } from '@apollo/client';

const GET_COURSE_SEARCH = gql`
  query getCourseSearch(
    $query: String
    $page: Int
    $contentfulIds_not_in: [ID]
    $contentfulIds_in: [ID]
    $practiceAreas: [ID]
    $specialRequirements: [ID]
    $specializationCredits: [ID]
    $dateRecorded_gte: DateTime
    $dateRecorded_lte: DateTime
    $pageSize: Int = 10
  ) {
    courseSearch(
      query: $query
      pageSize: $pageSize
      pageNumber: $page
      filters: {
        practiceAreaIds: $practiceAreas
        contentfulIds_not_in: $contentfulIds_not_in
        contentfulIds_in: $contentfulIds_in
        specialRequirementsIds: $specialRequirements
        specializationIds: $specializationCredits
        dateRecorded_gte: $dateRecorded_gte
        dateRecorded_lte: $dateRecorded_lte
      }
    ) {
      totalCount
      pageInfo {
        currentPage
        totalPages
      }
      edges {
        contentfulId
        slug
        title
        dateRecorded
        featureImageUrl
        totalCredits
        longDescription
        courseMaterialsUrl
        practiceAreas {
          contentfulId
          name
          slug
        }
        specialRequirementsCredits {
          contentfulId
          parentContentfulId
          parentName
          credits
          name
        }
        specializationCredits {
          contentfulId
          parentContentfulId
          parentName
          credits
          name
        }
        collections {
          title
          slug
          compliancePackageProductCode
        }
      }
    }
  }
`;

export default GET_COURSE_SEARCH;
