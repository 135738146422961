import React from 'react';
import styled from 'styled-components';
import { Nav } from 'components/nav';
import { ActiveLinkItem } from 'components/nav/ActiveLinkItem';
import { CEB_COLOR, CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';
import pluralize from 'util/pluralize';

export function CollectionSubNav({ results }) {
  return (
    <StyledCollectionSubNav>
      <ActiveLinkItem to="/learning/collections" exact>
        All Collections
      </ActiveLinkItem>
      <ActiveLinkItem to="/learning/collections/packages" exact>
        {results.compliancePackages.length} Compliance{' '}
        {pluralize(results.compliancePackages.length, 'Package')}
      </ActiveLinkItem>
      <ActiveLinkItem to="/learning/collections/other" exact>
        {results.otherCollections.length} Other{' '}
        {pluralize(results.otherCollections.length, 'Collection')}
      </ActiveLinkItem>
    </StyledCollectionSubNav>
  );
}
const StyledCollectionSubNav = styled(Nav)`
  background: transparent;
  box-shadow: none;
  border-bottom-color: ${CEB_COLOR_RGBA('BLACK_ZERO', 0.1)};
  border-bottom-width: 2px;
  font-family: 'Zilla Slab', serif;
  font-size: 18px;
  padding: 7px 0 0 0;
  li {
    font-family: inherit;
    a,
    a:visited {
      color: ${CEB_COLOR('BLACK')};
    }
  }
`;
