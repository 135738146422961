import React from 'react';
import styled from 'styled-components';
import { CourseProgressBar } from 'components/learning/cle-courses/CourseProgressBar';
import { PlayCourseButton } from 'components/learning/cle-courses/PlayCourseButton';
import { useHasAccess } from 'components/learning/cle-courses/AssetAccessButton';

export function CourseCardProgress({ course, referrer, collection }) {
  const { progress, courseMaterialsUrl } = course;
  const courseForAccess = React.useMemo(
    () => ({
      collections: [collection],
      ...course,
    }),
    [course, collection],
  );

  const hasAccess = useHasAccess(courseForAccess);
  const completed = !!progress?.salesforceCourseResultId;

  const showProgress = React.useMemo(() => {
    return progress && hasAccess && !completed;
  }, [progress, hasAccess, completed]);

  return !showProgress ? null : (
    <StyledCardProgress className="card-progress">
      <section>
        <PlayCourseButton course={courseForAccess} referrer={referrer} />
      </section>

      <section className="course-progress">
        <CourseProgressBar courseProgress={progress} />
      </section>

      {courseMaterialsUrl && (
        <section className="course-materials">
          <a
            href={courseMaterialsUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-download" />
            Course Materials
          </a>
        </section>
      )}
    </StyledCardProgress>
  );
}

const StyledCardProgress = styled.section`
  section {
    flex: 0;
    padding-top: 5px;
    a {
      width: 100%;
      box-sizing: border-box;
      text-align: center;
    }

    &:first-child {
      a {
        width: 100%;
        box-sizing: border-box;
        font-size: 0.8em;
        text-align: center;
        padding: 8px 0 7px;
        i {
          vertical-align: 0;
        }
      }
    }
    &.course-progress {
      flex: 1 1 auto;
    }
    &.course-materials {
      flex: 0 1 auto;
      font-family: 'BasisGrotesque', sans-serif;
      a {
        font-size: 13px;
        font-weight: bold;
        white-space: nowrap;
        i {
          margin-right: 0.5em;
        }
      }
    }
  }
`;
