import { gql } from '@apollo/client';
import { CourseData } from 'components/cle-library/course/getCleCourse';

export const GET_COURSE_WATCHLIST = gql`
  query getCourseWatchlist {
    courseWatchlist {
      id
      createdAt
      contentfulCourseId
      course {
        ...CourseData
      }
    }
  }

  ${CourseData}
`;
