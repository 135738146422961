import { gql } from '@apollo/client';

const GET_SUBSCRIPTIONS = gql`
  query getSubscriptions {
    user {
      subscriptions(statuses: [ACTIVE, PROVISIONING]) {
        status
        subscriptionId
        planCode
        planName
        subscriptionInterval
        amount
        createdTimestamp
        activatedTimestamp
        scheduledActivationTimestamp
        nextPeriodStartDate
        contractEndTimestamp
        remainingInterval
        contractRemainingIntervals
        providesCLE
        providesLegalResearch
        products {
          isRecurring
          productName
          productCode
          quantity
        }
      }
    }
    firm {
      name
      subscriptions(statuses: [ACTIVE, PROVISIONING]) {
        subscriptionId
        planCode
        planName
        subscriptionInterval
        amount
        createdTimestamp
        activatedTimestamp
        nextPeriodStartDate
        providesCLE
        providesLegalResearch
        products {
          isRecurring
          productName
          productCode
          quantity
        }
      }
    }
  }
`;

export default GET_SUBSCRIPTIONS;
