import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const CLELibraryHeaderTabNavigation = () => (
  <StyledNavigationTabs>
    <StyledLeftBorderFade />
    <NavLink className={({ isActive }) => (isActive ? ' active' : '')} to="/">
      CLE Courses
    </NavLink>
    <NavLink
      className={({ isActive }) => (isActive ? ' active' : '')}
      to="/collections/packages"
    >
      Compliance Packages
    </NavLink>
    <StyledRightBorderFade />
  </StyledNavigationTabs>
);

const StyledLeftBorderFade = styled.div`
  @media screen and (min-width: 666px) {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 40px;
    height: 7px;
    margin: 0 203px 0 0;
    background-image: linear-gradient(
      to right,
      #ffffff,
      rgba(255, 255, 255, 0)
    );
  }
`;

const StyledRightBorderFade = styled.div`
  @media screen and (min-width: 666px) {
    position: absolute;
    bottom: -1px;
    right: -203px;
    width: 40px;
    height: 7px;
    margin: 0 203px 0 0;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      #ffffff
    );
  }
`;

const StyledNavigationTabs = styled.div`
  position: relative;
  width: 100%;

  a {
    display: block;
    margin: 0 24px -1px 0;
    padding: 12px 24px 9px;

    background-color: #f9f9f9;

    font-family: BasisGrotesque;
    font-size: 20px;
    color: #343434;

    + a {
      border-top: solid 1px #bcbcbc;
    }
  }

  @media screen and (min-width: 666px) {
    border-bottom: solid 1px #bcbcbc;
    padding-left: 40px;
    a {
      display: inline-block;
      border-radius: 6px 6px 0 0;
      border: solid 1px #bcbcbc;

      &.active {
        background-color: white;
        border-bottom: solid 1px white;
      }
    }
  }
`;

export default CLELibraryHeaderTabNavigation;
