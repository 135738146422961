import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import RemoveIcon from 'components/common/icons/Remove';
import WatchlistIcon from 'components/common/icons/Watchlist';
import { useNavigateWithState } from 'components/routes/RedirectWithState';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { LearningNavContext } from '../LearningNav';
import { GET_COURSE_WATCHLIST } from './GET_COURSE_WATCHLIST';
import { useWatchlist } from './useWatchlist';

export function AddToWatchlistButton({
  course,
  showLabel = true,
  removable = false,
  onRemove,
}) {
  const {
    courseWatchlist,
    addCourseToWatchlist,
    removeCourseFromWatchlist,
  } = useWatchlist();
  const { contentfulId: contentfulCourseId, progress } = course || {};
  const navigate = useNavigateWithState();
  const completed = !!progress?.salesforceCourseResultId;

  const { setCourseAdded } = useContext(LearningNavContext);

  const onWatchlist = React.useMemo(() => {
    return courseWatchlist
      .map(listItem => listItem.contentfulCourseId)
      .includes(contentfulCourseId);
  }, [courseWatchlist, contentfulCourseId]);

  const handleClick = React.useCallback(
    async e => {
      if (!contentfulCourseId) return;
      if (!onWatchlist) {
        await addCourseToWatchlist({
          variables: {
            contentfulCourseId,
          },
          refetchQueries: [{ query: GET_COURSE_WATCHLIST }],
        });
        setCourseAdded(true);
      } else {
        if (!removable) navigate('/learning/watchlist');
        else {
          const watchlistItem = courseWatchlist.find(
            listItem => listItem.contentfulCourseId === contentfulCourseId,
          );
          if (watchlistItem)
            await removeCourseFromWatchlist({
              variables: {
                id: watchlistItem.id,
              },
              refetchQueries: [{ query: GET_COURSE_WATCHLIST }],
            });
          onRemove && onRemove(watchlistItem.course);
        }
      }
    },
    [
      addCourseToWatchlist,
      contentfulCourseId,
      navigate,
      onRemove,
      removable,
      courseWatchlist,
      onWatchlist,
      removeCourseFromWatchlist,
      setCourseAdded,
    ],
  );

  const label = React.useMemo(
    () =>
      onWatchlist
        ? removable
          ? 'Remove'
          : 'On Watchlist'
        : 'Add to Watchlist',
    [onWatchlist, removable],
  );

  if (completed && !onWatchlist) return null;

  return (
    <StyledWatchlistButton
      $onWatchlist={onWatchlist}
      onClick={handleClick}
      disabled={!contentfulCourseId}
      $removable={removable}
      title={label}
    >
      {removable && onWatchlist ? (
        <RemoveIcon />
      ) : (
        <WatchlistIcon
          className="watchlist"
          fill={!showLabel || onWatchlist ? CEB_COLOR('TEAL') : undefined}
        />
      )}
      {showLabel && <span>{label}</span>}
    </StyledWatchlistButton>
  );
}

const StyledWatchlistButton = styled.button`
  font-family: 'BasisGrotesque', sans-serif;
  font-size: 12px;
  font-weight: bold;
  color: ${({ $onWatchlist, $removable }) =>
    $onWatchlist && !$removable ? CEB_COLOR('TEAL') : 'inherit'};
  svg {
    vertical-align: -3px;
  }
  span {
    margin-left: 6px;
  }
`;
