import { GetAssetsQuery } from '__generated__/graphql';

export type ProductCodeFilter = string | RegExp | Array<string | RegExp>;

export function hasProductCode(
  assets: NonNullable<GetAssetsQuery['assets']>,
  productCode: ProductCodeFilter,
  quantity = true,
): boolean {
  return getProducts(assets, productCode, quantity)?.length > 0;
}

export function getProducts(
  assets: NonNullable<GetAssetsQuery['assets']>,
  productCode: ProductCodeFilter,
  quantity = true,
) {
  return (
    assets &&
    assets.filter(a => {
      if (productCode instanceof RegExp) {
        return a!.productCode!.match(productCode);
      }

      if (productCode instanceof Array) {
        return (
          productCode
            .map(c => hasProductCode(assets, c, quantity))
            .filter(b => b).length === productCode.length
        );
      }

      return (
        a!.productCode === productCode && (quantity ? a!.quantity! > 0 : true)
      );
    })
  );
}

export function hadProductCode(
  assets: NonNullable<GetAssetsQuery['assets']>,
  productCode: string | RegExp,
) {
  return hasProductCode(assets, productCode, false);
}

export function hasPersonalPassport(
  assets: NonNullable<GetAssetsQuery['assets']>,
) {
  return hasProductCode(assets, '55ppxx0000', false);
}

export function hasUnlimitedCLE(assets: NonNullable<GetAssetsQuery['assets']>) {
  return hasProductCode(assets, '02ucxx0000', false);
}

export function hasCoursePassAccess(
  assets: NonNullable<GetAssetsQuery['assets']>,
  quantity: boolean,
) {
  return hasProductCode(assets, /09cp(06|12)0000/, quantity);
}

export function hasPackageAccess(
  assets: NonNullable<GetAssetsQuery['assets']>,
) {
  const packages = assets
    ? assets.filter(a => a!.productCode!.match(/^22/))
    : [];
  return packages.length > 0;
}

export function hasCLEPassportAccess(
  assets: NonNullable<GetAssetsQuery['assets']>,
) {
  return hasPersonalPassport(assets) || hasUnlimitedCLE(assets);
}

export function hasCLEAccess(assets: NonNullable<GetAssetsQuery['assets']>) {
  return (
    hasCoursePassAccess(assets, false) ||
    hasPackageAccess(assets) ||
    hasCLEPassportAccess(assets)
  );
}
