import React from 'react';
import styled from 'styled-components';
import { PracticeAreaTags } from 'components/daily-news/PracticeAreaTags';
import { TrackedLink as Link } from 'components/common/TrackedLink';
import { useCoursePracticeAreaPathBuilder } from '../useCoursePracticeAreaPathBuilder';
import { AddToWatchlistButton } from 'components/learning/watchlist/AddToWatchlistButton';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';

export function CourseCardBodyTitle({
  course,
  path,
  referrer,
  rootSlugs,
  courseSearchBase,
  watchlistable,
  watchlistRemovable,
  onRemoveFromWatchlist,
}) {
  const { title, subtitle, contentfulId } = course;
  const practiceAreas = course.practiceAreas.filter(area =>
    rootSlugs.includes(area.slug),
  );

  const practiceAreaPathBuilder = useCoursePracticeAreaPathBuilder(
    courseSearchBase,
  );
  return (
    <>
      <StyledCardBodyTitle className="card-body-title">
        <h3>
          <Link to={path} state={{ referrer }}>
            {title}
          </Link>
        </h3>
        {practiceAreas && (
          <div className="card-practice-areas">
            <PracticeAreaTags
              practiceAreas={practiceAreas}
              pathBuilder={practiceAreaPathBuilder}
            />
            {watchlistable && (
              <AddToWatchlistButton
                course={course}
                removable={watchlistRemovable}
                onRemove={onRemoveFromWatchlist}
              />
            )}
          </div>
        )}
      </StyledCardBodyTitle>
      {subtitle && <h4>{subtitle}</h4>}
    </>
  );
}

const StyledCardBodyTitle = styled.section`
  h3 {
    color: ${CEB_COLOR('CEB_HEADER_BLUE')};
    display: block;
    flex: 1 1 auto;
    font-family: 'LyonDisplay', serif;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1.2;
    margin-top: 4px;
    a {
      color: ${CEB_COLOR('CEB_HEADER_BLUE')};
    }
  }
`;
