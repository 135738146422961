import { RawContentfulSummary } from 'components/common/ContentfulContent';
import { FormattedTime } from 'components/common/FormattedTime';
import React from 'react';
import pluralize from 'util/pluralize';
import {
  CLELibraryRequirements,
  StyledRequirementBubble,
} from '../CLELibraryRequirements';
import { PrintCertificateButton } from 'components/learning/cle-courses/PlayCourseButton';
import { CourseDate } from 'components/learning/cle-courses/RecordedDate';
import { combineSpecializations } from '../combineSpecializations';

export function CourseCardBody({ course, summaryLength }) {
  const {
    progress,
    dateRecorded,
    longDescription,
    totalCredits,
    specialRequirementsCredits,
  } = course;
  const completed = !!progress?.salesforceCourseResultId;

  const specializationCredits = React.useMemo(
    () => combineSpecializations(course.specializationCredits),
    [course.specializationCredits],
  );

  return completed ? (
    <section className="card-body-completed">
      <section className="card-body-completed-date">
        <CourseDate date={progress.updatedAt} format="MMM D, YYYY" flex>
          Completed
        </CourseDate>
      </section>
      <section>
        <PrintCertificateButton course={course} />
      </section>
    </section>
  ) : (
    <>
      <section className="card-body-recorded-date">
        Recorded&nbsp;
        <FormattedTime time={dateRecorded} format="MMM D, YYYY" flex />
      </section>
      <section className="card-body-summary">
        <RawContentfulSummary
          document={longDescription}
          summaryLength={summaryLength}
        />
        ...
      </section>
      <StyledRequirementBubble>
        {totalCredits} {pluralize(totalCredits, 'Hr')}
      </StyledRequirementBubble>
      {(specialRequirementsCredits.length > 0 ||
        specializationCredits.length > 0) && (
        <span className="including-message">Including:</span>
      )}
      <CLELibraryRequirements
        requirements={specialRequirementsCredits}
        color="rgba(68, 143, 120, 0.78)"
      />
      <CLELibraryRequirements
        requirements={specializationCredits}
        color="rgba(41, 54, 79, 0.65)"
      />
    </>
  );
}
