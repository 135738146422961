import React, { useCallback } from 'react';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import { PracticeAreaTags } from 'components/daily-news/PracticeAreaTags';
import { RawContentfulSummary } from 'components/common/ContentfulContent';
import pluralize from 'util/pluralize';
import { combineSpecializations } from 'components/cle-library/landing/combineSpecializations';
import {
  CLELibraryRequirements,
  StyledRequirementBubble,
} from 'components/cle-library/landing/CLELibraryRequirements';
import qs from 'util/qs';
import { TrackedLink } from 'components/common/TrackedLink';

export function CLELibraryPackagesSearchResult({ data, path }) {
  const groupedSpecializationCredits = React.useMemo(
    () => combineSpecializations(data.specializationCredits),
    [data.specializationCredits],
  );

  const practiceAreaPathBuilder = useCallback(
    area => `/?${qs.stringify({ practiceAreas: area.contentfulId })}`,
    [],
  );

  return (
    <StyledSearchResult>
      <div>
        <header>
          <div className="collection-contains">
            <i className="fas fa-archive" />
            This Collection contains{' '}
            <TrackedLink to={path}>
              {data.coursesCount} {pluralize(data.coursesCount, 'Course')}
            </TrackedLink>
          </div>
          <div className="card-practice-areas">
            {data.practiceAreas && (
              <PracticeAreaTags
                practiceAreas={data.practiceAreas}
                pathBuilder={practiceAreaPathBuilder}
              />
            )}
          </div>
        </header>
        <div>
          <section className="card-image">
            <TrackedLink to={path}>
              <img src={data.collectionImageUrl} alt="" />
            </TrackedLink>
          </section>
          <section className="card-body">
            <section className="card-body-title">
              <h3>
                <TrackedLink to={path}>{data.title}</TrackedLink>
              </h3>
            </section>
            <section className="card-body-summary">
              <RawContentfulSummary
                document={data.description}
                summaryLength={200}
              />
              ...
            </section>
            <section className="card-requirements total">
              <StyledRequirementBubble className="total">
                {data.totalCredits} {pluralize(data.totalCredits, 'Hr')}
              </StyledRequirementBubble>
              Total
              {(data.specialRequirementsCredits.length > 0 ||
                groupedSpecializationCredits.length > 0) && <>, including</>}
            </section>
            <section className="card-requirements">
              <CLELibraryRequirements
                requirements={data.specialRequirementsCredits}
                color="rgba(41, 54, 79, 0.65)"
              />
            </section>
            <section className="card-requirements">
              <CLELibraryRequirements
                requirements={groupedSpecializationCredits}
                color="rgba(68, 143, 120, 0.78)"
              />
            </section>
          </section>
        </div>
      </div>
    </StyledSearchResult>
  );
}

const StyledSearchResult = styled.li`
  margin: 24px 35px;
  list-style-type: none;
  > div {
    background: white;
    border-radius: 4px;
    border: 1px solid ${CEB_COLOR('QUILL_GRAY')};
    > div {
      display: flex;
      padding: 0 24px 0 14px;
      section {
        flex: 1 1 auto;
      }
    }

    header {
      display: flex;
      border-bottom: 1px solid ${CEB_COLOR('QUILL_GRAY')};

      .collection-contains {
        flex: 1;
        font-family: 'BasisGrotesque', sans-serif;
        font-size: 14px;
        padding: 8px 14px;
        i {
          margin-right: 0.5em;
        }
        a {
          font-weight: bold;
          color: #185cad;
        }
      }

      .card-practice-areas {
        text-align: right;
        ul {
          margin: 4px 7px 0 0;
        }
      }
    }

    .card-image {
      padding: 15px 0;
      max-width: 232px;
      img {
        width: 100%;
        display: block;
      }
    }
    .card-body {
      padding: 16px 0 16px 18px;
    }
    .card-body-title {
      display: flex;
      flex-direction: column;
      margin-bottom: 8px;
      h3 {
        color: ${CEB_COLOR('CEB_HEADER_BLUE')};
        flex: 1;
        font-family: 'LyonDisplay';
        font-size: 20px;
        font-stretch: normal;
        font-style: normal;
        font-weight: nromal;
        letter-spacing: normal;
        line-height: normal;
        a {
          color: ${CEB_COLOR('NICE_BLUE')};
        }
      }
    }

    .card-body-summary {
      font-family: 'Zilla Slab', serif;
      font-size: 15px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      margin-bottom: 8px;
    }

    .card-requirements {
      font-family: 'BasisGrotesque', sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      &.total {
        span {
          padding: 5px 7px;
        }
      }
      span {
        margin-bottom: 10px;
      }
    }
  }
`;
