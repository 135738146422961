import React from 'react';
import { StyleConstant } from 'util/getStyleConstant';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';

export const Nav = ({ children, className }) => (
  <StyledNav role="navigation" className={className}>
    <ul>{children}</ul>
  </StyledNav>
);

const StyledNav = styled.nav`
  background: ${CEB_COLOR('CARARRA')};
  border-bottom: 1px solid ${CEB_COLOR('ALTO')};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  padding: 0 25px;
  position: relative;
  z-index: 3;
  ul {
    list-style-type: none;
    text-align: left;
  }

  @media screen and (min-width: ${StyleConstant.BREAKPOINT_SMALL_HANDHELD}) {
    flex: 0 1 auto;
    li {
      display: inline-block;
      svg {
        margin-right: 35px;
        vertical-align: -4px;
      }
    }
  }

  @media only print {
    display: none;
  }
`;
