import React from 'react';
import styled from 'styled-components';
import TitledPage from 'components/common/TitledPage';
import Loading from 'components/common/Loading';
import { CLECollectionSearchResults } from '../collection/landing/CLECollectionSearchResults';
import CLELibraryPageHeader from '../CLELibraryPageHeader';
import CLELibraryHeaderTabNavigation from '../CLELibraryHeaderTabNavigation';
import { CLELibraryPackagesSearchResult } from './CLELibraryPackagesSearchResult';
import { CLELibraryAssetAccessButton } from '../CLELibraryAssetAccessButton';

export default function CLELibraryPackages() {
  return (
    <TitledPage title="CLE Collections">
      <CLELibraryPageHeader>
        <CLELibraryHeaderTabNavigation />
      </CLELibraryPageHeader>

      <StyledCLECollections>
        <StyledCLECollectionsHeading>
          <CLELibraryAssetAccessButton />
        </StyledCLECollectionsHeading>
        <Loading>
          <CLECollectionSearchResults noNav type="packages">
            {result => (
              <CLELibraryPackagesSearchResult
                data={result}
                key={`course-collection-${result.contentfulId}`}
                rootSlugs={[]}
                path={`/collections/packages/${result.slug}`}
              />
            )}
          </CLECollectionSearchResults>
        </Loading>
      </StyledCLECollections>
    </TitledPage>
  );
}

const StyledCLECollections = styled.div`
  max-width: 1062px;
  margin: 0 auto;
  height: 100%;
`;

const StyledCLECollectionsHeading = styled.div`
  margin: 0 35px;
  text-align: right;
`;
