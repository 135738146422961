import React from 'react';
import { TrackedLink as Link } from 'components/common/TrackedLink';
import styled from 'styled-components';
import { CEB_COLOR, CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';
import { StyleConstant } from 'util/getStyleConstant';

export const LinkItem = props => (
  <StyledLinkItem className={props.active ? 'active' : null}>
    <Link to={props.to}>{props.children}</Link>
  </StyledLinkItem>
);

const StyledLinkItem = styled.li`
  font-family: 'BasisGrotesque', sans-serif;
  border-bottom: 3px solid transparent;
  margin-right: 30px;
  &:last-child {
    margin-right: 0;
  }
  &.active {
    font-weight: bold;
    border-bottom-color: ${CEB_COLOR('SAFFRON')};
  }
  > a,
  > a:visited {
    display: block;
    padding: 17px 0 11px;
    color: ${CEB_COLOR('LUCKY_POINT')};
    &:focus {
      outline: 0;
      border-bottom: 1px solid ${CEB_COLOR_RGBA('DANUBE', 0.6)};
      margin-bottom: -1px;
    }
  }

  @media screen and (min-width: ${StyleConstant.BREAKPOINT_SMALL_HANDHELD}) {
    display: inline-block;
  }
`;
