import React from 'react';
import styled from 'styled-components';
import {
  EvaluateCourseButton,
  PlayCourseButton,
  PrintCertificateButton,
} from './PlayCourseButton';
import { useCourseProgress } from './useCourseProgress';
import { CourseProgressBar } from './CourseProgressBar';
import { CourseDate } from './RecordedDate';
import { coursePlayUrl } from 'util/courseUrl';
import { AssetAccessButton, useHasAccess } from './AssetAccessButton';
import { ConditionalLink } from 'components/common/ConditionalLink';

export function CourseSidebar({
  isPlayer = false,
  course,
  course: {
    contentfulId,
    featureImageUrl,
    courseMaterialsUrl,
    courseMaterialsFileName,
    speakers,
  },
}) {
  const courseProgress = useCourseProgress([contentfulId]);
  const { salesforceCourseResultId, currentPlayheadInMs } = courseProgress;
  const completed = !!salesforceCourseResultId;
  const hasAccess = useHasAccess(course);
  return (
    <StyledCourseSidebar>
      {!isPlayer && (
        <>
          <section className="featured-image">
            <ConditionalLink
              condition={hasAccess}
              to={`${coursePlayUrl(course.slug)}`}
            >
              <img src={featureImageUrl} alt="" />
            </ConditionalLink>
          </section>
          {!hasAccess ? (
            <section>
              <AssetAccessButton course={course} />
            </section>
          ) : (
            <>
              <section className="progress-section">
                <CourseProgressBar courseProgress={courseProgress} />
              </section>
              {!completed && (
                <section>
                  <PlayCourseButton course={course} />
                </section>
              )}
            </>
          )}
        </>
      )}
      {completed && (
        <>
          <section className={completed ? 'completed-section' : ''}>
            <CourseDate date={courseProgress.updatedAt}>Completed</CourseDate>
          </section>
          <section className={completed ? 'print-section' : ''}>
            <PrintCertificateButton course={course} />
          </section>

          <section>
            <p>
              We value your feedback. Please give us your evaluation of this
              course and the instructors by completing this optional survey:{' '}
            </p>
            <EvaluateCourseButton course={course} />
          </section>
        </>
      )}
      {courseMaterialsUrl && hasAccess && (
        <section className="course-materials">
          <h4>Course Materials</h4>
          <div>
            <a
              href={courseMaterialsUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="far fa-file-pdf" />
              {courseMaterialsFileName}
            </a>
          </div>
        </section>
      )}
      <section>
        <h4>Speakers</h4>
        <ul>
          {speakers.map(speaker => (
            <li key={speaker.webDisplayName}>{speaker.webDisplayName}</li>
          ))}
        </ul>
      </section>
      <CCSection />
    </StyledCourseSidebar>
  );
}

export const StyledCourseSidebar = styled.div`
  box-sizing: border-box;
  max-width: 336px;
  min-width: 336px;
  padding: 24px 0 24px 36px;
  > section {
    margin-bottom: 24px;
    p {
      font-family: 'Zilla Slab', serif;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.71;
      margin-bottom: 12px;
    }
    &.print-section {
      a,
      span {
        padding: 7px;
        width: 100%;
        text-align: center;
        box-sizing: border-box;
      }
    }
    &.featured-image {
      a {
        display: block;
      }
    }
    &.featured-image,
    &.progress-section {
      margin-bottom: 12px;
      img {
        display: block;
      }
    }
    &.completed-section {
      div {
        text-align: center;
        font-size: 14px;
      }
    }
    &.cc,
    &.course-materials {
      a,
      span {
        padding-left: 26px;
        position: relative;
        > i {
          display: block;
          position: absolute;
          left: 0;
          font-size: 20px;
        }
      }
      a {
        display: block;
        font-size: 17px;
        font-weight: bold;
        line-height: 1.18;
      }
      span {
        font-family: 'Zilla Slab', serif;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.71;
        > i {
          top: -2px;
        }
      }
    }
    > h4 {
      font-family: 'BasisGrotesque', sans-serif;
      font-weight: 900;
      margin-bottom: 17px;
      text-transform: uppercase;
    }
    > a {
      width: 100%;
      text-align: center;
      box-sizing: border-box;
    }
    > ul {
      font-family: 'Zilla Slab', serif;
      font-size: 17px;
      font-stretch: normal;
      font-style: normal;
      font-weight: bold;
      line-height: 1.53;
      list-style-type: none;
    }
  }
  img {
    width: 100%;
    height: auto;
  }
`;

export function CCSection() {
  return (
    <section className="cc">
      <span>
        <i className="fas fa-closed-captioning" />
        All CEB on-demand programs have human-generated captions available to
        meet or exceed ADA Section 508 requirements for accessibility.
      </span>
    </section>
  );
}
