import React from 'react';
import styled from 'styled-components';

export const InCurrency = ({
  currency = '$',
  price = '0',
  interval,
  showCents = 'auto',
  ...props
}) => {
  let period;
  let formattedPrice;

  switch (interval) {
    case 'MONTHLY':
      period = '/mo';
      break;
    case 'MONTHLY+':
      period = '/mo and up';
      break;
    case 'YEARLY':
      period = '/yr';
      break;
    case 'YEARLY+':
      period = '/yr and up';
      break;
    case 'none':
      period = '';
      break;
    default:
      period = interval;
  }

  switch (showCents) {
    case 'auto':
      formattedPrice =
        parseFloat(price, 10)
          .toLocaleString()
          .match(/\./) || isNaN(Number(price))
          ? Number(price).toFixed(2)
          : price;
      break;
    case true:
      formattedPrice = Number(price).toFixed(2);
      break;
    case false:
      formattedPrice = parseFloat(price);
      break;
    default:
      formattedPrice = price;
  }

  return (
    <StyledCurrency className="formatted-price" {...props}>
      {price !== 'Free' ? (
        <>
          <span className="currency">{currency}</span>
          <span className="price">{formattedPrice}</span>
          <span className="period">{period}</span>
        </>
      ) : (
        <span className="price">{price}</span>
      )}
    </StyledCurrency>
  );
};

const StyledCurrency = styled.span`
  &.formatted-price {
    text-align: inherit;
    white-space: nowrap;
    font-size: 1em;
    margin-bottom: 6px;
    font-weight: normal;
  }
  > .price {
    font-family: 'Zilla Slab', serif;
    font-feature-settings: 'lnum';
    white-space: nowrap;
  }

  > .period {
    font-size: 0.8em;
  }

  > .currency {
  }
`;
