import React from 'react';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { truncateToNearestWord } from '../util/truncateToNearestWord';

export function useCourseMetaData(
  course,
  defaultTitle = 'CEB',
  summaryLength = 150,
  trailer = ' …',
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const truncate = React.useCallback(
    truncateToNearestWord(summaryLength, trailer),
    [summaryLength, trailer],
  );
  return React.useMemo(() => {
    const titleTemplate = `%s - ${defaultTitle}`;
    return {
      title: course.title,
      meta: {
        'og:title': titleTemplate.replace('%s', course.title),
        'og:image': course.featureImageUrl,
        'og:description': truncate(
          documentToPlainTextString(course.longDescription),
        ),
      },
      link: {
        canonical: `https://learning.ceb.com/course/${course.slug}`,
      },
      defaultTitle,
      titleTemplate,
    };
  }, [course, defaultTitle, truncate]);
}

export function useCollectionMetaData(
  collection,
  defaultTitle = 'CEB',
  summaryLength = 150,
  trailer = ' …',
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const truncate = React.useCallback(
    truncateToNearestWord(summaryLength, trailer),
    [summaryLength, trailer],
  );
  return React.useMemo(() => {
    const titleTemplate = `%s - ${defaultTitle}`;
    return {
      title: collection.title,
      meta: {
        'og:title': titleTemplate.replace('%s', collection.title),
        'og:image': collection.collectionImageUrl,
        'og:description': truncate(
          documentToPlainTextString(collection.description),
        ),
      },
      link: {
        canonical: `https://learning.ceb.com/collections/packages/${
          collection.slug
        }`,
      },
      defaultTitle,
      titleTemplate,
    };
  }, [collection, defaultTitle, truncate]);
}

export function usePostMetaData(
  post,
  defaultTitle = 'CEB Articles',
  summaryLength = 150,
  trailer = ' …',
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const truncate = React.useCallback(
    truncateToNearestWord(summaryLength, trailer),
    [summaryLength, trailer],
  );

  return React.useMemo(() => {
    const titleTemplate = `%s - ${defaultTitle}`;

    return post
      ? {
          title: post?.title,
          meta: {
            'og:title': titleTemplate.replace('%s', post?.title),
            'og:image': post?.featuredImage?.url,
            'og:description': truncate(
              documentToPlainTextString(post.content.json),
            ),
            'og:type': 'article',
          },
          link: {
            canonical: `https://research.ceb.com/posts/${post?.slug}`,
          },
          defaultTitle,
          titleTemplate,
        }
      : {
          title: 'DailyNews',
          titleTemplate,
          defaultTitle,
        };
  }, [post, defaultTitle, truncate]);
}
