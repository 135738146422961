import queryString from 'util/qs';

import flow from 'lodash/fp/flow';
import cond from 'lodash/fp/cond';
import isEmpty from 'lodash/fp/isEmpty';
const notEmpty = a => !isEmpty(a);

export default function uriStringify(path, query) {
  return flow(
    queryString.stringify,
    cond([[isEmpty, str => path], [notEmpty, str => `${path}?${str}`]]),
  )(query);
}
