import React from 'react';
import styled from 'styled-components';
import { CollectionSubNav } from './CollectionSubNav';
import { WithHeader } from 'components/layouts/WithHeader';
import { useCollectionSearch } from './useCollectionSearch';
import { sortBy } from 'lodash/fp';

export function CLECollectionSearchResults({ children, type, noNav = false }) {
  const [data] = useCollectionSearch();

  const results = React.useMemo(() => {
    const edgesSortedByTitle = sortBy('title')(
      data.coursesCollectionSearch.edges,
    );

    const compliancePackages = edgesSortedByTitle.filter(
      collection => !!collection.compliancePackageProductCode,
    );
    const otherCollections = edgesSortedByTitle.filter(
      collection => !collection.compliancePackageProductCode,
    );

    return {
      edges:
        edgesSortedByTitle.filter(result => {
          switch (type) {
            case 'packages':
              return !!result.compliancePackageProductCode;
            case 'other':
              return !result.compliancePackageProductCode;
            default:
              return result;
          }
        }) || [],
      compliancePackages,
      otherCollections,
    };
  }, [data, type]);

  return (
    <StyledSearchResults>
      <WithHeader>
        {!noNav && <CollectionSubNav results={results} />}
        <ul>
          {(results.edges || []).map((edge, index) => children(edge, index))}
        </ul>
      </WithHeader>
    </StyledSearchResults>
  );
}

const StyledSearchResults = styled.div`
  height: 100%;
  > ul {
    list-style-type: none;
    margin-top: 7px;
    overflow: hidden;
  }
`;
