import React from 'react';
import { TrackedLink } from 'components/common/TrackedLink';
import { StyledAccessSection } from './StyledAccessSection';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';

export function PackageAccess() {
  return (
    <StyledPackageAccessSection className="package-access-section">
      <section>
        <div>
          <img
            src="/images/subscriptions/logos/compliance-package.svg"
            alt="Compliance Package Logo"
          />
        </div>
        <div className="message">
          <div>
            Your Compliance Package gives you access to the courses listed
            below. For access to our entire CLE library,{' '}
            <TrackedLink to="/account/purchases/upgrade-mcle">
              upgrade to a CLEPassport
            </TrackedLink>
            .
          </div>
        </div>
        <div>
          <div>
            <TrackedLink to="/learning/courses">Browse CLE Courses</TrackedLink>
          </div>
        </div>
      </section>
    </StyledPackageAccessSection>
  );
}

const StyledPackageAccessSection = styled(StyledAccessSection)`
  margin-top: 50px;
  > section > div {
    &:last-child {
      div {
        align-self: start;
      }
      a {
        background-color: ${CEB_COLOR('NICE_BLUE')};
        border-radius: 3px;
        color: white;
        display: block;
        font-family: 'BasisGrotesque', sans-serif;
        font-size: 16px;
        padding: 12px 23px 12px;
      }
    }
  }
`;
