import { gql } from '@apollo/client';

export const ADD_COURSE_TO_WATCHLIST = gql`
  mutation AddCourseToWatchlist($contentfulCourseId: String!) {
    addCourseToWatchlist(contentfulCourseId: $contentfulCourseId) {
      id
      contentfulCourseId
    }
  }
`;
