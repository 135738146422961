import React from 'react';

const FilterIcon = () => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      stroke="#29364F"
      strokeWidth="1.5"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M8.751 10.75h6M1.251 10.75h3M8.751 10.75a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0h0zM10.251 3.25h-9M14.751 3.25a2.25 2.25 0 1 0-4.5 0 2.25 2.25 0 0 0 4.5 0z" />
    </g>
  </svg>
);

export default FilterIcon;
