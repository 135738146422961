import React from 'react';
import { StyleConstant } from 'util/getStyleConstant';
import styled from 'styled-components';
import { useIsRawLayout } from './useIsRawLayout';

export function WithHeader({ children, ...props }) {
  const raw = useIsRawLayout();

  return (
    <StyledWithHeader
      {...props}
      data-layout={`${!raw ? '' : 'raw-'}with-header`}
    >
      {children[0]}
      <section>{children[1]}</section>
    </StyledWithHeader>
  );
}
const StyledWithHeader = styled.section`
  @media screen and (min-width: ${StyleConstant.BREAKPOINT_HANDHELD}) {
    max-height: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
    grid-template-areas: 'with-header-layout-header' 'with-header-layout-main';
    > header,
    > nav {
      grid-area: with-header-layout-header;
    }
    > section {
      grid-area: with-header-layout-main;
      height: 100%;
      overflow: auto;
    }
  }
`;
