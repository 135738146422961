import { basePaths } from 'app-constants';

let prefix;

export const setCollectionUrlPrefix = slug => {
  prefix = slug;
};

export default function collectionsUrl(slug, type) {
  return ['/', prefix, basePaths.collections.replace(/^\//, ''), type, slug]
    .filter(Boolean)
    .join('/')
    .replace(/\/+/g, '/');
}

export function collectionUrl(slug, type) {
  return collectionsUrl(slug, 'other');
}

export function packageUrl(slug, type) {
  return collectionsUrl(slug, 'packages');
}

export const collectionSearchUrl = collectionsUrl();
