import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import React from 'react';
import styled from 'styled-components';

export function CLELibraryAssetAccessButton() {
  return (
    <StyledCourseButton href="https://www.ceb.com/pricing/mcle/">
      <i className="fas fa-list" />
      See Access Options
    </StyledCourseButton>
  );
}

const StyledCourseButton = styled.a`
  display: inline-block;
  font-family: 'BasisGrotesque', sans-serif;
  font-size: 14px;
  padding: 8px 21px;
  border-radius: 5px;
  background-color: ${CEB_COLOR('BLUE_RIBBON')};
  color: #ffffff;

  i {
    margin-right: 10px;
    font-size: 20px;
    vertical-align: -3px;
  }
}`;
