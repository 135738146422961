import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import styled from 'styled-components';

export const StyledFilterSectionTitle = styled.h3.attrs(
  ({ sidebarPadding = 25 }) => ({
    sidebarPadding,
  }),
)`
  font-size: 19px;
  margin-bottom: 0px;
  margin-top: 25px;
  padding-right: ${({ sidebarPadding }) => sidebarPadding}px;
  padding-left: ${({ sidebarPadding }) => sidebarPadding}px;
  padding-bottom: 18px;
`;

export const StyledFilterSectionTitleWithBorder = styled(
  StyledFilterSectionTitle,
)`
  border-bottom: 1px solid ${CEB_COLOR('ALTO')};
`;
