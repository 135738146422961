import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useCoursesWithProgress } from 'components/cle-library/landing/useResultsWithProgress';
import { ADD_COURSE_TO_WATCHLIST } from './ADD_COURSE_TO_WATCHLIST';
import { GET_COURSE_WATCHLIST } from './GET_COURSE_WATCHLIST';
import { REMOVE_COURSE_FROM_WATCHLIST } from './REMOVE_COURSE_FROM_WATCHLIST';

export function useWatchlist() {
  const { data, loading } = useQuery(GET_COURSE_WATCHLIST);
  const [mutation, addLoading] = useMutation(ADD_COURSE_TO_WATCHLIST);
  const [removeMutation, removeLoading] = useMutation(
    REMOVE_COURSE_FROM_WATCHLIST,
  );

  const withProgress = useCoursesWithProgress(
    data?.courseWatchlist?.map(({ course }) => course) || [],
  );

  const courseWatchlist = React.useMemo(() => {
    return (data?.courseWatchlist || []).map(listItem => {
      return {
        ...listItem,
        course: withProgress?.find(
          course => course.contentfulId === listItem.contentfulCourseId,
        ),
      };
    });
  }, [data, withProgress]);

  return {
    loading: loading || addLoading || removeLoading,
    courseWatchlist,
    addCourseToWatchlist: mutation,
    removeCourseFromWatchlist: removeMutation,
  };
}
