import React from 'react';
import { isActive } from 'util/isActive';
import { LinkItem } from './LinkItem';
import { useLocation } from 'react-router-dom';
import uriStringify from 'util/uriStringify';

const isActiveExact = ({ pathname, to }) => to === pathname.replace(/\/$/, '');

export const ActiveLinkItem = ({ to, children, exact, exclude, query }) => {
  const { pathname } = useLocation();
  return (
    <LinkItem
      to={uriStringify(to, { query })}
      active={(exact ? isActiveExact : isActive)({ pathname, to, exclude })}
    >
      {children}
    </LinkItem>
  );
};
