import useSuspendableQuery from 'hooks/useSuspendableQuery';
import { useParams } from 'react-router';
import GET_COURSES_COLLECTION from '../../cle-library/collection/GET_COURSES_COLLECTION';

export function useCoursesCollection() {
  const { slug } = useParams();

  const { data, error } = useSuspendableQuery(GET_COURSES_COLLECTION, {
    variables: {
      slug,
    },
    fetchPolicy: 'network-only',
  });
  return { data, error };
}
