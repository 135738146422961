import React from 'react';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import { useCourseProgress } from './useCourseProgress';
import { courseEvaluateUrl, coursePlayUrl } from 'util/courseUrl';
import { StyledCourseButton } from './StyledCourseButton';
import { useHasAccess } from './AssetAccessButton';
import { AppContext } from 'components/app-context';
import { ConditionalTooltip } from 'components/common/Tooltip';

export function PlayCourseButton({
  course,
  course: { slug, contentfulId },
  referrer,
}) {
  const { currentPlayheadInMs, salesforceCourseResultId } = useCourseProgress(
    [contentfulId],
    true,
  );
  const completed = !!salesforceCourseResultId;

  const path = `${coursePlayUrl(slug)}`;

  const hasAccess = useHasAccess(course);

  return completed || !hasAccess ? null : (
    <StyledCourseButton to={path} state={{ referrer }}>
      <i className="fas fa-play-circle" />
      {currentPlayheadInMs > 0 ? 'Resume' : 'Start'} this Course
    </StyledCourseButton>
  );
}

export function EvaluateCourseButton({ course: { slug, contentfulId } }) {
  const { salesforceCourseResultId } = useCourseProgress([contentfulId], true);
  const completed = !!salesforceCourseResultId;

  const path = courseEvaluateUrl(slug);
  return !completed ? null : (
    <StyledCourseButton
      to={path}
      background={CEB_COLOR('DUSTY_TEAL')}
      color={CEB_COLOR('WHITE')}
    >
      <i className="fas fa-pencil-alt" />
      Evaluate Course
    </StyledCourseButton>
  );
}

export function PrintCertificateButton({ course: { contentfulId } }) {
  const { user } = React.useContext(AppContext);
  const { salesforceCourseResultId } = useCourseProgress([contentfulId], true);
  const disabled = React.useMemo(
    () => !salesforceCourseResultId || !user?.barNumber,
    [salesforceCourseResultId, user],
  );
  const path = `/learning/print/course-cert/${salesforceCourseResultId}`;

  return (
    <ConditionalTooltip
      condition={disabled}
      title={'No Bar Number - will not report to CA Bar'}
    >
      <StyledCourseButton
        as={disabled ? 'span' : 'a'}
        href={path}
        fontSize={14}
        background={CEB_COLOR('MERCURY')}
        color={CEB_COLOR('BLACK')}
        target="_blank"
        rel="noopener noreferrer"
        disabled={disabled}
      >
        <i className="fas fa-print" />
        Print Certificate
      </StyledCourseButton>
    </ConditionalTooltip>
  );
}
