import groupBy from 'lodash/fp/groupBy';
import map from 'lodash/fp/map';
import reduce from 'lodash/fp/reduce';
import flow from 'lodash/fp/flow';

export const combineSpecializations = flow(
  groupBy('parentContentfulId'),
  map(
    reduce(
      (sum, n) => (sum ? { ...sum, credits: sum.credits + n.credits } : n),
      undefined,
    ),
  ),
);
