import { gql } from '@apollo/client';

const GET_COURSES_COLLECTION = gql`
  query getCourseCollection(
    $slug: String
    $compliancePackageProductCode: String
  ) {
    coursesCollection(
      slug: $slug
      compliancePackageProductCode: $compliancePackageProductCode
    ) {
      contentfulId
      active
      slug
      title
      description
      compliancePackageProductCode
      collectionImageUrl
      coursesCount
      totalCredits
      practiceAreas {
        contentfulId
        slug
        name
      }
      specializationCredits {
        credits
        name
        contentfulId
        parentName
        parentContentfulId
      }
      specialRequirementsCredits {
        credits
        name
        contentfulId
        parentName
        parentContentfulId
      }
      courses {
        contentfulId
        slug
        title
        dateRecorded
        featureImageUrl
        totalCredits
        longDescription
        courseMaterialsUrl
        practiceAreas {
          contentfulId
          name
          slug
        }
        specialRequirementsCredits {
          contentfulId
          parentContentfulId
          parentName
          credits
          name
        }
        specializationCredits {
          contentfulId
          parentContentfulId
          parentName
          credits
          name
        }
      }
    }
  }
`;

export default GET_COURSES_COLLECTION;
