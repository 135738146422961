import { gql } from '@apollo/client';

export const GET_COLLECTIONS = gql`
  query getCourseCollections($page: Int) {
    coursesCollectionSearch(pageSize: 50, pageNumber: $page) {
      totalCount
      pageInfo {
        totalPages
        currentPage
      }
      edges {
        contentfulId
        active
        slug
        title
        description
        compliancePackageProductCode
        collectionImageUrl
        coursesCount
        totalCredits
        practiceAreas {
          contentfulId
          slug
          name
        }
        specializationCredits {
          credits
          name
          contentfulId
          parentName
          parentContentfulId
        }
        specialRequirementsCredits {
          credits
          name
          contentfulId
          parentName
          parentContentfulId
        }
      }
    }
  }
`;
