import React from 'react';
import styled from 'styled-components';
import { FormattedTime } from 'components/common/FormattedTime';

export function RecordedDate({ course }) {
  return (
    <CourseDate date={course.dateRecorded} format="MMM D, YYYY" flex>
      Recorded
    </CourseDate>
  );
}

export function CourseDate({ date, children, format, flex }) {
  return (
    <StyledRecordedDate>
      <i className="far fa-calendar" />
      {children}&nbsp;
      <FormattedTime time={date} utc={false} format={format} flex={flex} />
    </StyledRecordedDate>
  );
}
const StyledRecordedDate = styled.div`
  font-family: 'Zilla Slab', serif;
  font-size: 13px;
  i {
    font-size: 14px;
    vertical-align: top;
    margin-right: 6px;
  }
  time {
    font-weight: bold;
  }
`;
