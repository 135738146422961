import React from 'react';

const Account = props => (
  <svg width={20} height={20} {...props}>
    <g
      transform="translate(1 1)"
      stroke="#FFF"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect x={0.563} y={0.563} width={16.875} height={16.875} rx={1.5} />
      <path d="M9.563 11.813h4.5M3.938 11.813h2.25M9.563 11.813a1.687 1.687 0 1 1-3.375 0 1.687 1.687 0 0 1 3.375 0zM10.688 6.188h-6.75M14.063 6.188a1.688 1.688 0 1 0-3.375 0 1.688 1.688 0 0 0 3.375 0z" />
    </g>
  </svg>
);

export default Account;
