import React from 'react';
import { useLocation, useNavigate } from 'react-router';

export function BackButton({
  onClick,
  label = 'Back',
  fallbackPath = '/',
  children,
  to,
  ...props
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const goBack = React.useCallback(
    e => {
      if (to || location.state?.from) {
        onClick
          ? onClick(e) && navigate(to || location.state?.from)
          : navigate(to || location.state?.from);
      } else navigate(fallbackPath || -1);
    },
    [location, navigate, onClick, to, fallbackPath],
  );

  return (
    <button onClick={goBack} {...props}>
      {children ? (
        children
      ) : (
        <>
          <i className="fa fa-angle-left" />
          {label}
        </>
      )}
    </button>
  );
}
