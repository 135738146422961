import React from 'react';
import styled, { css } from 'styled-components';
import { InCurrency } from 'components/common/InCurrency';
import { CEB_COLOR, CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';
import { TrackedLink } from 'components/common/TrackedLink';

const CONTENT = [
  'Content',
  'Hours of included content',
  '24/7 on-demand viewing',
  'ADA-compatible transcription available',
  'Courses provide participatory credit',
  'Track your progress on requirements',
  'Download slides & materials',
  'Select any Course from our catalog',
  'Satisfies all MCLE requirements',
  'Discount for Firm purchasers',
  'Monthly billing for annual access',
];

const CONTENT_FLAGS = {
  coursepass: [
    'Get a couple credits',
    '6 or 12 hours',
    true,
    true,
    true,
    true,
    true,
    true,
    false,
    false,
    false,
  ],
  'compliance-package': [
    'Meet requirements',
    '25 hours',
    true,
    true,
    true,
    true,
    true,
    false,
    true,
    false,
    false,
  ],
  passport: [
    'Unlimited access',
    'Unlimited',
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ],
};

const CONTENT_ACCENT = {
  coursepass: '#e19d26',
  'compliance-package': '#8d1e1d',
  passport: '#6da545',
};

const CONTENT_PRICING = {
  coursepass: [498, 675],
  'compliance-package': [795],
  passport: ['890+'],
};
const CONTENT_CTA = {
  coursepass: 'Buy CoursePass',
  'compliance-package': 'Choose a Package',
  passport: 'Buy CLE Passport',
};

function optionContent(option, ...contentFlags) {
  return contentFlags.reduce((t, v, i) => {
    return { ...t, [CONTENT[i]]: v };
  }, {});
}

export function PlanTable({ options, subscriptionsData }) {
  const optionsWithFlags = options.map(option => {
    return {
      ...option,
      content: optionContent(option, ...CONTENT_FLAGS[option.slug]),
    };
  });

  return (
    <StyledPlanTable cellPadding="0" cellSpacing="0">
      <thead>
        <tr>
          <th>Available MCLE products:</th>
          {optionsWithFlags.map((option, i) => (
            <th key={`${option.slug}-logo-${i}`}>
              <div>
                <img
                  src={`/images/subscriptions/logos/${option.logo}`}
                  alt={option.name}
                />
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {CONTENT.map((content, i) => {
          return (
            <tr data-content-key={content} key={`content-row-${i}`}>
              <td>
                <div>{content}</div>
              </td>
              {optionsWithFlags.map((option, i) => {
                const optionContent = option.content[content];
                return (
                  <td
                    className={option.slug}
                    key={`${option.slug}-feature-${i}`}
                  >
                    <div>
                      {typeof optionContent === 'string'
                        ? optionContent
                        : optionContent && (
                            <span>
                              <i className="fas fa-check" />
                            </span>
                          )}
                    </div>
                  </td>
                );
              })}
            </tr>
          );
        })}
        <tr>
          <td>
            <div>Pricing</div>
          </td>
          <td colSpan={optionsWithFlags.length}>
            <div>
              CLE Passport is a one-year plan. Other plans allow access for up
              to one year or until consumed.
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td />
          {optionsWithFlags.map((option, i) => (
            <td className={option.slug} key={`${option.slug}-price-${i}`}>
              <div>
                <section>
                  <ContentPrice price={CONTENT_PRICING[option.slug]} />
                </section>
                <section>
                  <ContentBuyButton
                    offering={option}
                    subscriptions={subscriptionsData.user?.subscriptions}
                  />
                </section>
              </div>
            </td>
          ))}
        </tr>
      </tfoot>
    </StyledPlanTable>
  );
}

function ContentBuyButton({ offering, subscriptions }) {
  const hasProvidesLegalResearch =
    offering.providesLegalResearch &&
    subscriptions?.filter(p => p.providesLegalResearch)?.length > 0;

  const hasProvidesCle =
    offering.providesCle &&
    subscriptions?.filter(
      s => s.providesCLE && s.products.filter(p => p.quantity >= 1)?.length > 0,
    )?.length > 0;

  return hasProvidesLegalResearch || hasProvidesCle ? (
    <a href="mailto:customer_service@ceb.ucla.edu">Contact us to upgrade</a>
  ) : (
    <TrackedLink to={`/checkout/${offering.slug}`}>
      {CONTENT_CTA[offering.slug]}
    </TrackedLink>
  );
}

function ContentPrice({ price }) {
  return price.length > 1 ? (
    <>
      <InCurrency price={price[0]} /> <b>or</b> <InCurrency price={price[1]} />
    </>
  ) : (
    <>
      {typeof price[0] === 'string' && price[0].match(/\+$/) ? 'From ' : ''}
      <InCurrency price={parseInt(price[0], 10)} />
    </>
  );
}

const StyledPlanTable = styled.table`
  font-family: BasisGrotesque;
  font-size: 14px;
  min-width: 992px;
  max-width: 1176px;
  td,
  th {
    height: inherit;
    text-align: center;
    div {
      box-sizing: border-box;
      height: 100%;
      padding: 8px 15px;
      position: relative;
      width: 100%;
      &:after {
        bottom: 0;
        content: ' ';
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
      }
    }
    &:first-child {
      text-align: left;
      vertical-align: center;
      div {
        padding: 12px 14px 10px 8px;
      }
    }
    + td,
    + th {
      padding-right: 10px;
      &:last-child {
        padding-right: 0;
      }
    }
  }

  th {
    &:first-child {
      font-family: 'Zilla Slab', serif;
      font-size: 20px;
      font-weight: bold;
    }
    div {
      background: none;
    }
  }

  thead {
    img {
      width: 100%;
    }
  }

  tr,
  tfoot {
    height: 1px;
    td {
      ${Object.keys(CONTENT_ACCENT).map(
        key => css`
          &.${key} {
            color: ${CONTENT_ACCENT[key]};
            a {
              background: ${CONTENT_ACCENT[key]};
            }
          }
        `,
      )}
      a {
        border-radius: 5px;
        color: white;
        display: block;
        margin-top: 13px;
        padding: 12px 23px 11px;
        text-align: center;
        white-space: nowrap;
      }
      + td {
        div {
          border: 1px solid ${CEB_COLOR('MERCURY')};
          border-top-width: 0;
          border-bottom-width: 0;
          &:after {
            background: white;
          }
        }
      }
    }
  }

  tbody tr {
    &:first-child {
      td + td {
        div {
          font-family: BasisGrotesque;
          font-size: 18px;
          padding: 16px 10px;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          border-top-width: 1px;
        }
      }
      td:first-child {
        vertical-align: bottom;
        div {
          background: #394c64;
          border-top-left-radius: 4px;
          color: white;
          font-weight: bold;
          height: auto;
        }
      }
    }
    &:nth-child(even) {
      td {
        div {
          background: ${CEB_COLOR_RGBA('COD_GRAY', 0.02)};
          &:after {
            background: ${CEB_COLOR_RGBA('COD_GRAY', 0.02)};
          }
        }
        + td div:after {
          background: white;
        }
      }
    }
    &:last-child {
      td {
        &:first-child {
          div {
            background: #394c64;
            border-bottom-left-radius: 4px;
            color: white;
            font-weight: bold;
          }
        }
        &[colspan] {
          div {
            background: #cce7f7;
            padding: 6px 9px;
            font-size: 15px;
            font-weight: normal;
            font-style: italic;
            line-height: 1.47;
          }
        }
      }
    }
  }

  tbody {
    td {
      &:first-child {
        white-space: nowrap;
      }
      ${Object.keys(CONTENT_ACCENT).map(
        key => css`
          &.${key} {
            span {
              color: white;
              background: ${CONTENT_ACCENT[key]};
            }
          }
        `,
      )}
      span {
        background: transparent;
        border-radius: 20px;
        display: inline-block;
        font-size: 8px;
        height: 20px;
        line-height: 22px;
        text-align: center;
        width: 20px;
      }
    }
    tr[data-content-key='Hours of included content'] {
      td + td div {
        font-family: 'BasisGrotesque', sans-serif;
        font-size: 15px;
        font-weight: 900;
        font-style: italic;
        line-height: 1.47;
        color: black;
      }
    }
  }

  tfoot td + td div {
    border-bottom-width: 1px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    color: ${CEB_COLOR('BLACK')};
    font-family: 'BasisGrotesque', sans-serif;
    font-size: 18px;
    font-style: normal;
    line-height: 1.22;
    min-width: 234px;
    padding: 13px 15px 16px;
    .formatted-price,
    > span {
      font-weight: 900;
      .currency,
      .price {
        font-family: inherit;
        font-weight: inherit;
      }
    }
  }
`;
