import React from 'react';
import ReactDOM from 'react-dom';
import styled, { createGlobalStyle, css } from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';

export interface TooltipProps {
  children?: React.ReactNode;
  darkmode?: boolean;
  inline?: boolean;
  title?: string | React.JSXElementConstructor;
  visible?: boolean;
  small?: boolean;
  rounded?: boolean;
  className?: string;
  onEnter?: Function;
  onLeave?: Function;
}

export interface ConditionalTooltipProps extends TooltipProps {
  condition: boolean;
}

interface StyledTooltipProps {
  $inline?: boolean;
}

interface TooltipRendererProps {
  title?: string;
  darkmode?: boolean;
  rounded?: boolean;
  small?: boolean;
  target: HTMLDivElement | null;
  className?: string;
}

interface StyledTooltipRendererProps {
  $x: number;
  $y: number;
  $scrollTop: number;
  $scrollLeft: number;
  $width: number;
  $darkmode?: boolean;
  $small?: boolean;
  $rounded?: boolean;
}

export function ConditionalTooltip({
  condition,
  title,
  visible,
  children,
  className,
  onEnter,
  onLeave,
}: ConditionalTooltipProps) {
  return condition ? (
    <Tooltip
      visible={visible}
      title={title}
      className={className}
      onEnter={onEnter}
      onLeave={onLeave}
    >
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
}

export function Tooltip(props: TooltipProps) {
  const [show, setShow] = React.useState(props.visible);

  React.useEffect(() => {
    setShow(props.visible);
  }, [props.visible]);

  const onEnter = React.useCallback(
    (e: React.MouseEvent) => {
      props.onEnter && props.onEnter(e);
      return setShow(true);
    },
    [setShow, props.onEnter],
  );

  const onLeave = React.useCallback(
    (e: React.MouseEvent) => {
      props.onLeave && props.onLeave(e);
      return setShow(false);
    },
    [setShow, props.onLeave],
  );

  const tooltipTargetRef = React.useRef<HTMLDivElement>(null);

  return (
    <StyledTooltip
      className="tooltip-target"
      $inline={props.inline}
      ref={tooltipTargetRef}
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
    >
      {props.children}
      {show && props.title ? (
        <TooltipRenderer
          className={props.className}
          title={props.title}
          target={tooltipTargetRef.current}
          darkmode={props.darkmode}
          small={props.small}
          rounded={props.rounded}
        />
      ) : null}
    </StyledTooltip>
  );
}

const StyledTooltip = styled.div<StyledTooltipProps>`
  position: relative;
  ${({ $inline }) =>
    $inline &&
    css`
      display: inline;
    `}
`;

function TooltipRenderer({
  target,
  title,
  darkmode,
  small,
  rounded,
  className,
}: TooltipRendererProps) {
  const { x, y, width } = React.useMemo(() => {
    return (
      target?.getBoundingClientRect() || {
        x: 0,
        y: 0,
        width: 0,
      }
    );
  }, [target]);

  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  return ReactDOM.createPortal(
    <>
      <div className="tooltip-container">
        <div className={`tooltip${className ? ` ${className}` : ''}`}>
          {title}
        </div>
      </div>
      <StyledTooltipRenderer
        $x={x}
        $y={y}
        $width={width}
        $scrollTop={scrollTop}
        $scrollLeft={scrollLeft}
        $darkmode={darkmode}
        $small={small}
        $rounded={rounded}
      />
    </>,
    document.body,
  );
}

const StyledTooltipRenderer = createGlobalStyle<StyledTooltipRendererProps>`
  .tooltip-container {
    top: ${({ $y, $scrollTop }) => $y + $scrollTop}px;
    left: ${({ $x, $scrollLeft }) => $x + $scrollLeft}px;
    width: ${({ $width }) => $width}px;
    height: 0;
    position: absolute;
    z-index: 101;
    > .tooltip {
     
      background: ${({ $darkmode }) => ($darkmode ? CEB_COLOR('BLACK') : 'white')};
      border: solid 1px  ${({ $darkmode }) => ($darkmode ? CEB_COLOR('BLACK') : CEB_COLOR('MERCURY'))};
      border-radius: 3px;
      bottom: 100%;
      color: ${({ $darkmode }) => ($darkmode ? 'white' : CEB_COLOR('BLACK'))};
      font-family: ${({ $small }) => ($small ? css`var(--FONT_SANS_SERIF)` : css`var(--FONT_SERIF)`)};
      font-size: ${({ $small }) => ($small ? '12px' : '14px')};
      left: 50%;
      line-height: ${({ $small }) => ($small ? '1.08' : '2.29')};
      margin-bottom: 4px;
      margin-left: 10px;
      ${({ $small }) =>
        !$small &&
        css`
          font-weight: bold;
          min-width: 100%;
        `};
      padding: ${({ $small }) => ($small ? '6px 7.5px 4px' : '0 20px')};
      position: absolute;
      text-align: center;
      transform-origin: top;
      transform: translate(-50%, 0px);
      white-space: nowrap;
      z-index: 1;
      &:after,
      &:before {
        border: solid transparent;
        content: ' ';
        height: 0;
        left: 50%;
        pointer-events: none;
        position: absolute;
        top: 100%;
        width: 0;
      }

      &:after {
        border-color: rgba(255, 255, 255, 0);
        border-top-color: ${({ $darkmode }) => ($darkmode ? CEB_COLOR('BLACK') : 'white')};
        border-width: 4px;
        margin-left: -4px;
      }
      &:before {
        border-color: rgba(194, 225, 245, 0);
        border-top-color: ${({ $darkmode }) => ($darkmode ? CEB_COLOR('BLACK') : CEB_COLOR('MERCURY'))};
        border-width: 5px;
        margin-left: -5px;
      }
    }
  }
`;
