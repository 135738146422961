import React from 'react';
import styled from 'styled-components';
import { StyledAccessSection } from './StyledAccessSection';

export function PassportAccess() {
  return (
    <StyledPassportAccessSection className="passport-access-section">
      <section>
        <div>
          <img
            src="/images/subscriptions/logos/passport.svg"
            alt="CLEPassport Logo"
          />
        </div>
        <div className="message">
          <div>
            You have unlimited access to all courses and collections through
            your CLEPassport.
          </div>
        </div>
      </section>
    </StyledPassportAccessSection>
  );
}

const StyledPassportAccessSection = styled(StyledAccessSection)`
  > section > div > div {
    font-size: 15px;
    line-height: 1.47;
  }
`;
