import React from 'react';
import styled from 'styled-components';
import { CCSection } from 'components/learning/cle-courses/CourseSidebar';
import { CLELibraryAssetAccessButton } from '../CLELibraryAssetAccessButton';
import { StyledCourseSidebar } from 'components/learning/cle-courses/CourseSidebar';

export function CLELibraryPackageSidebar({ collection }) {
  return (
    <StyledPackageSidebar>
      <StyledModifiedCourseSidebar>
        <section className="featured-image">
          <img src={collection.collectionImageUrl} alt={collection.title} />
        </section>
        <section>
          <CLELibraryAssetAccessButton />
        </section>
        <CCSection />
      </StyledModifiedCourseSidebar>
    </StyledPackageSidebar>
  );
}

const StyledPackageSidebar = styled.div`
  div {
    background-color: #f6fbff;
    padding: 20px;
  }
`;

const StyledModifiedCourseSidebar = styled(StyledCourseSidebar)`
  padding: 0;
  max-width: 340px;
  min-width: 340px;
  .cc {
    margin: 0;
  }
`;
