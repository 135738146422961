import React from 'react';
import { Nav } from 'components/nav';
import { ActiveLinkItem } from 'components/nav/ActiveLinkItem';
import styled, { css } from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import { ConditionalTooltip } from 'components/common/Tooltip';

export const LearningNavContext = React.createContext({ breadcrumbs: [] });

export const LearningNav = () => {
  const { breadcrumbs, courseAdded } = React.useContext(LearningNavContext);
  return (
    <StyledLearningNav isBreadcrumbs={breadcrumbs.length}>
      {!breadcrumbs.length ? (
        <>
          <ActiveLinkItem to="/learning" exact>
            Overview
          </ActiveLinkItem>
          <ActiveLinkItem to="/learning/credits" exact>
            MCLE Credits and Certificates
          </ActiveLinkItem>
          <ActiveLinkItem to="/learning/courses">CLE Courses</ActiveLinkItem>
          <ActiveLinkItem to="/learning/collections">
            CLE Collections
          </ActiveLinkItem>
          <ActiveLinkItem to="/learning/watchlist">
            <ConditionalTooltip
              condition={courseAdded}
              visible={courseAdded}
              title={
                <StyledCourseAddedTooltip>
                  <i className="fa fa-check" /> Course Added
                </StyledCourseAddedTooltip>
              }
            >
              Watchlist
            </ConditionalTooltip>
          </ActiveLinkItem>
        </>
      ) : (
        <>
          <ActiveLinkItem to="/learning" exact>
            Learning
          </ActiveLinkItem>
          {breadcrumbs.map((crumb, i) =>
            i + 1 !== breadcrumbs.length ? (
              <ActiveLinkItem key={crumb.path} to={crumb.path} exact>
                {crumb.title}
              </ActiveLinkItem>
            ) : (
              <li key={crumb.path}>
                <span>{crumb.title}</span>
              </li>
            ),
          )}
        </>
      )}
    </StyledLearningNav>
  );
};

const StyledCourseAddedTooltip = styled.span`
  color: ${CEB_COLOR('TEAL')};
  font-family: 'Zilla Slab', serif;
  font-size: 14px;
  font-weight: bold;
  i {
    margin-right: 6px;
  }
`;

const StyledLearningNav = styled(Nav)`
  li {
    position: relative;
    span {
      display: block;
      padding: 17px 0 11px;
      color: ${CEB_COLOR('SLATE_GREY')};
    }
  }
  ${({ isBreadcrumbs }) =>
    isBreadcrumbs &&
    css`
      ul li {
        margin-right: 0;
        a {
          font-family: 'BasisGrotesque', sans-serif;
          font-size: 15px;
          color: CEB_COLOR('CEB_HEADER_BLUE');
          display: inline-block;
        }
        &:first-child {
          a {
            font-weight: bold;
          }
        }
        &:after {
          display: inline-block;
          content: ' ';
          height: 0;
          width: 0;
          border-width: 3.5px 5px;
          border-color: transparent;
          border-style: solid;
          border-left-color: inherit;
          opacity: 0.6;
          margin: 0px 7px 0px 14px;
          vertical-align: 2px;
        }
        &:last-child:after {
          display: none;
        }
      }
    `};
`;
