import styled from 'styled-components';

export const StyledAccessSection = styled.section`
  > section {
    display: flex;
    + section {
      margin-top: 24px;
    }
    > div {
      &:first-child {
        flex: 0 1 auto;
        margin-left: 0;
        img {
          max-width: 210px;
        }
      }
      flex: 1 1 auto;
      margin-left: 32px;
      font-family: 'Zilla Slab', serif;
      font-size: 13px;
      display: flex;
      a {
        white-space: nowrap;
      }
      div {
        align-self: center;
        flex: 1 1 auto;
      }
      &.message {
        font-size: 15px;
        line-height: 1.47;
      }
    }
  }
`;
