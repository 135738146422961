import React from 'react';
import useQueryString from 'hooks/useQueryString';
import useSuspendableQuery from 'hooks/useSuspendableQuery';
import { useLocation } from 'react-router';
import qs from 'util/qs';
import { GET_COLLECTIONS } from './GET_COLLECTIONS';

export function useCollectionSearch() {
  const { page, ...queryString } = useQueryString();
  const { pathname } = useLocation();

  const variables = React.useMemo(() => {
    return {
      page,
    };
  }, [page]);

  const { data } = useSuspendableQuery(GET_COLLECTIONS, { variables });

  const href = React.useCallback(
    page =>
      `${pathname}?${qs.stringify({
        ...queryString,
        page,
      })}`,
    [pathname, queryString],
  );

  const filteredData = React.useMemo(() => {
    return {
      ...data,
      coursesCollectionSearch: {
        ...data.coursesCollectionSearch,
        edges: data.coursesCollectionSearch.edges.filter(
          collection => collection.active,
        ),
      },
    };
  }, [data]);

  return [filteredData, page, href];
}
